@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300;400;500;600;700&display=swap');


* {
  user-select: none;
}
*::selection {
  background: none;
}
*::-moz-selection {
  background: none;
}


h1 {
  font-size: calc(6em + 1vw);
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.2;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

h2 {
  font-size: calc(3em + 1vw);
  text-transform: capitalize;
  font-weight: 500;
  color: var(--main-color);
  line-height: 1.2;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

h3 {
  font-size: calc(1.8em + 1vw);
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

h4 {
  font-size: 35px;
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

h5 {
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

p {
  line-height: 35px;
}

.gallery-content {
  columns: 5 320px;
}

.gallery-item {
  columns: 5 320px;
}

.gallery-content img {
  margin: 10px;
}

.pswp__img {
  object-fit: cover !important;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: calc(2em + 1vw);
  }

  h4 {
    font-size: calc(1em + 1vw);
  }

}

.content-directorios {
  align-self: center;
}