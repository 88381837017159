@import url("./css/styles.css");
@import url("./css/slider.css");
@import url("./css/socialmediaicon.css");
@import url("./css/botonStyles.css");
@tailwind base;
@tailwind components;
@tailwind utilities;



/*-----------------
    # Typography
-----------------*/
@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300;400;500;600;700&display=swap');

:root {
  --main-color: #BE1622;
  --secondary-color: #FF6600;
  --heading-color: #000000;
  --paragraph-color: #000000;
  --heading-font: "Yanone", sans-serif;
  --body-font: "Roboto", sans-serif;
  --line-height30: 1.7;
  --bg-footer: #000000;
  --btn: #BE1622;
  --btn-hover: #d30e1b;
  --scroll-color: rgb(0, 195, 0);
}

.kJVksy {
  background: var(--main-color);
}

html {
  overflow-x: hidden;
  font-family: var(--body-font);
}

* {
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  color: var(--paragraph-color);
  font-family: var(--body-font);
  line-height: var(--line-height30);
}

body::-webkit-scrollbar {
  display: none;
}

.burguer-menu {
  background-color: var(--main-color);
  padding: 15px;
  color: white;
}

.btn-global {
  background: var(--secondary-color);
  color: white;
  border-radius: 30px;
}

.breadcrumb-area {
  padding: 220px 0 120px;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-overlay {
  position: relative;
  background-size: cover !important;
}

.bg-overlay:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.432);
}


.bg-overlay-2 {
  position: relative;
  background-size: cover !important;
}

.bg-overlay-2:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(233, 0, 0, 0.432);
}

.bg-overlay h1 {
  position: relative;
  z-index: 1;
}

.call-to-action-area {
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.content-text {
  position: relative;
  z-index: 1;
}

.bg-footer {
  background: var(--bg-footer);
}

.social-area {
  list-style: none;
}

.social-area li {
  margin: 5px;
  float: left;
}

.wp-icon {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  box-shadow: 0 2px 2px #999;
  padding: 0px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 25px;
  font-weight: normal;
  line-height: 2em;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
}

.loader {
  position: relative;
  width: 50px;
  height: 50px;
  border: 4px solid transparent;
  border-radius: 50%;
  border-top: 4px solid var(--heading-color);
  animation: rotate 1.5s linear infinite;
}

.loader::before,
.loader::after {
  position: absolute;
  content: "";
  border-radius: 50%;
  box-sizing: border-box;
  border: 4px solid transparent;
}

.loader::after {
  inset: 0;
  border-bottom: 4px solid var(--main-color);
  animation: rotate 0.5s linear infinite reverse;
}

.loader::before {
  inset: 4px;
  border-left: 4px solid var(--main-color);
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/*
Form Reset
*/

.formContent {
  width: 95% !important;
}

.formContent__checkOpt {
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  align-self: center;
  align-items: center;
}

.form-check-label {
  margin-left: 20px !important;
}

.row_reverse {
  display: flex;
  flex-direction: row-reverse;
}

.row_normal {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 768px) {
  .content-text-bg {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column-reverse !important;
  }

  .breadcrumb-area {
    padding: 180px 0 100px;
  }
}


/* Back To Top*/

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 100px;
  right: 25px;
  z-index: 20;
}

.icon-style {
  background-color: var(--secondary-color);
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.absolute-modal {
  z-index: 99999;
}

.mobile-menu {
  left: -200%;
  transition: 0.5s;
}

.mobile-menu.active {
  left: 0;
}

.mobile-menu ul li ul {
  display: none;
}

.mobile-menu ul li:hover ul {
  display: block;
}

.visor_Counter {
  position: fixed;
  bottom: 10px;
  left: 5px;
  border-radius: 50%;
  color: white;
  padding: 16px;
  cursor: pointer;
  z-index: 9;
}


/*Progress Bar*/
#progressBarContainer {
  position: fixed;
  z-index: 20;
  background: rgba(255,255,255,0.05);
  width: 100%;
  top: 0;
  left: 0;
}


#progressBar {
  background: var(--scroll-color);
  transform-origin: top left;
  transform: scale(0,0);
}

#progressBarContainer,
#progressBar {
  height: 6px;
}



.video-container{
  width: 100vw;
  height: auto;
  position: relative;
}

.video-container::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.208);

}

 .bg-video {
  width: 100%;
  height: 100%;
}


#text{
  position: absolute;
  color: #FFFFFF;
  left: 15%;
  top: 40%;
}

@media (min-aspect-ratio: 16/9) {
  .video-container iframe {
    height: 50vw;
    height: 57vw;
  }
  
}
@media (max-aspect-ratio: 16/9) {
  .video-container iframe {
    height: 157vw;
    object-fit: cover;
  }
  #text{
    top: 25%;
  }
}

.bg-theme-color{
  background: #595959;
}

.bgCountentVideo {
  position: relative;
  padding: 100px 0 0;
  margin-bottom: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-bottom-left-radius: 100% 80%;
  border-bottom-right-radius: 100% 80%;
}
.bgCountentVideo .info {
  width: 60%;
  margin: 0 auto -80px;
}
.bgCountentVideo .countentVideoInfo {
  position: relative;
  text-align: center;
  color: white;
}
.bgCountent::before,
.bgFormHome::before,
.bgCountentVideo::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.bgCountentVideo::before {
  border-bottom-left-radius: 100% 80%;
  border-bottom-right-radius: 100% 80%;
  background-color: var(--overlay-video-content);
}
.bgCountent::before,
.bgFormHome::before {
  background-color: rgba(0, 0, 0, 0.6);
}
@media screen and (max-width:569px) {
  .bgCountentVideo {
    margin-bottom: 100px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .bgCountentVideo::before{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .bgCountentVideo .info {
    width: 90%;
    margin: 0 auto -30px;
  }
}