.boton {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: var(--btn);
	color: #fff;
	font-weight: 500;
	border: none;
	cursor: pointer;
	text-transform: capitalize;
	transition: .5s ease all;
	position: relative;
	overflow: hidden;
}

.boton span {
	position: relative;
	z-index: 2;
	transition: .3s ease all;
}

/* primer estilo */

.boton.one::after {
	content: "";
	width: 100%;
	height: 300px;
	background: var(--btn-hover);
	position: absolute;
	z-index: 1;
	top: -300px;
	left: 0;
	transition: .6s ease-in-out all;
	border-radius: 0px 0px 300px 300px;
}

.boton.one:hover::after {
	top: 0;
}

/* segundo estilo */

.boton.two::after {
	content: "";
	width: 100%;
	height: 100%;
	background: var(--btn-hover);
	position: absolute;
	z-index: 1;
	top: -80px;
	left: 0;
	transition: .6s ease-in-out all;
}

.boton.two:hover::after {
	top: 0;
}

/* tercer estilo */

.boton.three::after {
	content: "";
	width: 1px;
	height: 1px;
	background: none;
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transition: .9s ease-in-out all;
	border-radius: 100px;
	transform-origin: center;
}

.boton.three:hover::after {
	transform: scale(400);
	background: var(--btn-hover);
}
